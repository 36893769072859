import React from "react";

const Floor = () => {
  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]}>
      <planeBufferGeometry args={[1100, 1100]} />
      <meshStandardMaterial
        color="#303030"
        attach="material"
        opacity={0.9}
        roughness={1}
        metalness={0.2}
      />
      {}
    </mesh>
  );
};

export default Floor;
