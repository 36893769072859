import { Loader } from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import axios from "axios";
import React, { useState } from "react";
import Particles from "react-particles-js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./Contact.css";
import ContactModel from "./ContactModel";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const submitEmail = async (e) => {
    e.preventDefault();
    setDisabled(true);
    axios({
      method: "POST",
      url: "https://headsnap.io:8443/contact",

      headers: { "Content-Type": "application/json" },
      data: { name: name, email: email, message: message },
    }).then((response) => {
      console.log(response);
      if (response.status === 200) 
        {
        alert("Message Sent.");
        resetForm();
        setDisabled(false);
      } else if (response.data.flag === "fail") {
        alert(response.data.data);
        setDisabled(false);
      }
    });
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
  };
  const [messageModel] = useLoader(GLTFLoader, ["/models/message.glb"]);

  return (
    <div className="section">
      <Canvas
        style={{
          height: "100vh",
          width: "50vw",
        }}
        camera={{ fov: 45, near: 0.1, far: 1000, position: [0, 0, 8] }}
        id="canvas-contact"
        // shadows
      >

	  </Canvas>

      <Loader
        containerStyles={{
          background:
            "radial-gradient(circle farthest-corner at center top,#071021,#19324a)",
        }} // Flex layout styles
        innerStyles={{
          backgroundColor: "salmon",
          width: "50vw",
        }} // Inner container styles
        barStyles={{
          backgroundColor: "lightgreen",
        }} // Loading-bar styles
        dataInterpolation={(p) => `Loading ${Math.round(p)}%`}
        initialState={(active) => active}
        dataStyles={{
          color: "#fafafa",
          fontSize: "25px",
          fontFamily: "Raleway",
          fontWeight: "500",
        }}
      />

      <div className="container">
        <Particles
          id="particles"
          params={{
            particles: {
              number: {
                value: 500,
                density: {
                  enable: true,
                  value_area: 1000,
                },
              },
              color: {
                value: "#91fff8",
                // value: "#daf277",
              },
              opacity: {
                value: 0.5,
                anim: {
                  enable: true,
                },
              },
              size: {
                value: 5,
                random: true,
                anim: {
                  enable: true,
                  speed: 5,
                },
              },
              line_linked: {
                enable: false,
              },
              move: {
                speed: 0.4,
              },
            },
          }}
        />
        <div className="right">
          <h2 className="title">Contact Me</h2>
          <p className="tag">
          For any queries, you can contact me at laurentiu@bitheap.tech
	  </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
