/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { BoxGeometry } from "three";

export default function Model({ pos, tree }) {
  const group = useRef();
  const { materials } = tree;
  const geometry = new BoxGeometry(5, 6, 100); // A tall, box-like geometry.

  return (
    <group ref={group} position={pos} dispose={null}>
      <mesh
        geometry={geometry}
        material={materials["mullions"]}
        rotation={[-1.54, 0, 0]}
        scale={Math.random() + 0.7}
      />
    </group>
  );
}
