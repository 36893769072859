import React from "react";
import Tree from "./Tree";

const Trees = ({ tree }) => {
  const treePositions = [


  ];
  for (let i = 0; i < 120; i++) {
    treePositions.push([
      -750 - Math.floor(Math.random() * 45),
      0,
      Math.floor((Math.random() - 0.5) * 950),
    ]);
    treePositions.push([
      750 + Math.floor(Math.random() * 45),
      0,
      Math.floor((Math.random() - 0.5) * 950),
    ]);
    treePositions.push([
      Math.floor((Math.random() - 0.5) * 950),
      0,
      -750 - Math.floor(Math.random() * 45),
    ]);
    treePositions.push([
      Math.floor((Math.random() - 0.5) * 950),
      0,
      750 + Math.floor(Math.random() * 45),
    ]);
  }
  return treePositions.map((pos, index) => (
    <Tree tree={tree} pos={pos} key={index} />
  ));
};

export default Trees;
